<template>
  <div :class="css">
    <div class="text-danger"><p v-for="(error,idx) in errors" :key="idx">{{ error }}</p></div>
    <input type="file" name="file" class="d-none" />
    <div class="file-upload-in" :class="inputCss" @click="upload">{{ prompt }}
      <div class="upload-progress">
        <div class="bar" style="width: 0%;"></div>
      </div>
    </div>
  </div>
</template>
<script>
import FileUploader from './file.vue'

export default {
  extends: FileUploader,
  props: {
    imageOnly: {
      type: Boolean,
      default: true
    },
    prompt: {
      type: String,
      default: 'Add images here'
    },
    inputCss: {
      type: String,
      default: 'outlined-select primary-text primary-border'
    }
  }
}
</script>
